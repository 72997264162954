<template>
  <div class="full-width">
    <div class="row items-start">
      <q-list>
        <q-item class="row items-center q-px-none">
          <q-item-section>
            <q-checkbox @input="$set(settings, 'comments', $event)" :value="settings.comments" label="Allow Comments" />
          </q-item-section>
          <q-item-section avatar>
            <q-icon name="help">
            <q-tooltip>Allow users to post comments on the module</q-tooltip>
            </q-icon>
          </q-item-section>
        </q-item>
        <q-item class="row items-center q-px-none">
          <q-item-section>
            <q-checkbox @input="$set(settings, 'restrictProgression', $event)" :value="settings.restrictProgression" label="Restrict Module Progression" />
          </q-item-section>
          <q-item-section avatar>
            <q-icon name="help">
            <q-tooltip>Prevent users from skipping ahead in the module</q-tooltip>
            </q-icon>
          </q-item-section>
        </q-item>
        <q-item class="row items-center q-px-none">
          <q-item-section>
            <q-checkbox @input="$emit('update:is_global', $event)" :value="is_global" label="Share Module With All Mid-States Members" />
          </q-item-section>
          <q-item-section avatar>
            <q-icon name="help">
            <q-tooltip>Share this module with all other Mid-States members</q-tooltip>
            </q-icon>
          </q-item-section>
        </q-item>
        <q-item class="row items-center q-px-none">
          <q-item-section>
            <q-checkbox @input="$set(settings, 'bypassIpRestrictions', $event)" :value="settings.bypassIpRestrictions" label="Bypass IP Restrictions" />
          </q-item-section>
          <q-item-section avatar>
            <q-icon name="help">
            <q-tooltip>Allow this module to be viewed outside of your restricted network</q-tooltip>
            </q-icon>
          </q-item-section>
        </q-item>
        <q-item class="q-px-sm q-mt-xl">
          <q-item-section>
            <span class="caption caption-header-color">Points Earned For Completing Module</span>
            <q-input filled @change="$emit('update:points', $event.target.value)" :value="points" type="number" class="full-width q-input-inner-module select-inputs-styles input-height-custom q-input-darktheme border-radius-5" />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<script>
export default {
  props: ['is_global', 'settings', 'points']
}
</script>

<style>

</style>
