<template>
  <div class="full-width">
    <div class="row justify-around">
      <q-card class="bg-lmsBackground col-xl-3 col-lg-3 col-md-5 col-sm-12 col-xs-12 cursor-pointer q-my-xs" @click="$set(settings, 'browsable', 'true')" :bordered="settings.browsable === 'true'" :style="`border-color: ${getColor('primary')}`">
        <q-card-section class="row items-center full-height q-pl-xs">
          <div class="col-2">
            <q-radio :value="settings.browsable" @input="$set(settings, 'browsable', $event)" val="true" keep-color color="primary"></q-radio>
          </div>
          <div class="col-10 full-height">
            <div class="row">
              <div class="text-h6">Browsable</div>
            </div>
            <div class="row">
              <div class="text-caption">Allow any user to find the module</div>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <q-card class="bg-lmsBackground col-xl-3 col-lg-3 col-md-5 col-sm-12 col-xs-12 cursor-pointer q-my-xs" @click="$set(settings, 'browsable', 'false')" :bordered="settings.browsable === 'false'" :style="`border-color: ${getColor('primary')}`">
        <q-card-section class="row items-center full-height q-pl-xs">
          <div class="col-2">
            <q-radio :value="settings.browsable" @input="$set(settings, 'browsable', $event)" val="false" keep-color color="primary"></q-radio>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="text-h6">Not Browsable</div>
            </div>
            <div class="row">
              <div class="text-caption">Users will not be able to find the module. The module can still be assigned to any user</div>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <q-card class="bg-lmsBackground col-xl-3 col-lg-3 col-md-5 col-sm-12 col-xs-12 cursor-pointer q-my-xs" @click="$set(settings, 'browsable', 'restricted')" :bordered="settings.browsable === 'restricted'" :style="`border-color: ${getColor('primary')}`">
        <q-card-section class="row items-center full-height q-pl-xs">
          <div class="col-2">
            <q-radio :value="settings.browsable" @input="$set(settings, 'browsable', $event)" val="restricted" keep-color color="primary"></q-radio>
          </div>
          <div class="col-10 full-height">
            <div class="row">
              <div class="text-h6">Restricted</div>
            </div>
            <div class="row full-height">
              <div class="text-caption">Restrict which users can find the module. The module can still be assigned to any user</div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div v-if="settings.browsable === 'restricted'">
      <q-separator inset class="q-my-xl"/>
      <div class="row" v-if="settings.browsable === 'restricted'">
        <div class="text-h6">Restrictions</div>
        <div class="col-12 text-caption">Select the User Groups, Locations, or Roles that you want to be able to find the module</div>
        <div class="col-12 q-my-sm">
          <filter-select-multiple :options="$store.getters['departments/list']" v-bind:selection.sync="browsingRestrictions.departments" label="User Groups" emit-value />
        </div>
        <div class="col-12 q-my-sm">
          <filter-select-multiple :options="$store.getters['locations/list']" v-bind:selection.sync="browsingRestrictions.locations" label="Locations" emit-value />
        </div>
        <div class="col-12 q-my-sm">
          <filter-select-multiple :options="$store.getters['auth/rolesList']" v-bind:selection.sync="browsingRestrictions.roles" label="Roles" emit-value />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filterSelectMultiple from 'components/filterSelectMultiple'
import { colors } from 'quasar'
export default {
  components: { filterSelectMultiple },
  props: ['browsing', 'browsingRestrictions', 'settings', 'hasRestrictions', 'restrictions'],
  methods: {
    getColor (color) {
      return colors.getBrand(color)
    }
  }
}
</script>

<style>

</style>
