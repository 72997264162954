<template>
  <q-select v-model="selectionName" map-options :label="label" use-input @filter="filterFn" input-debounce="0" options-cover popup-content-class="treeSelectPopUp bg-lmsBackground primaryText-text">
    <template v-slot:no-option>
      <TreeMenuItem v-for="option in options" :key="option.id" :option="option" :inset="0" :enableBranchNodes="enableBranchNodes" :idValue="idValue" :labelValue="labelValue" @selection="itemSelected" :filterString="filter" :excludedUid="excludedUid" />
    </template>
  </q-select>
</template>

<script>
import TreeMenuItem from 'components/TreeMenuItem'

export default {
  name: 'CategorySelect',
  props: {
    label: {
      type: String
    },
    selection: {
      type: String
    },
    enableBranchNodes: {
      type: Boolean,
      default: false
    },
    idValue: {
      type: String,
      default: null
    },
    labelValue: {
      type: String,
      default: null
    },
    categoryOptions: {
      type: Array,
      required: true
    },
    excludedUid: {
      type: String
    }
  },
  components: { TreeMenuItem },
  data () {
    return {
      filter: null,
      selectionName: 'Select',
      flatOptions: []
    }
  },
  computed: {
    options () {
      return this.categoryOptions
    }
  },
  watch: {
    categoryOptions () {
      this.flattenOptions()
      let parent = this.flatOptions.find(option => option.uid === this.selection)

      this.selectionName = parent ? parent.name : null
    }
  },
  methods: {
    itemSelected (item) {
      this.selectionName = item.label
      this.$emit('update:selection', item.id)
      this.$emit('input', item)
      this.filter = null
    },
    flattenOptions (level = this.categoryOptions) {
      level.forEach(cat => {
        this.flatOptions.push(cat)
        if (cat.children) {
          this.flattenOptions(cat.children)
        }
        return cat
      })
    },
    filterFn (val, update) {
      this.filter = val
      if (val.length > 0) {
        this.selectionName = ''
      }
      update(() => { })
    }
  },
  created () {
    this.flattenOptions()
    let parent = this.flatOptions.find(option => option.uid === this.selection)

    this.selectionName = parent ? parent.name : null
  }
}
</script>

<style lang="stylus">
.treeSelectPopUp
  max-height 400px
</style>
