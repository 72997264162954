<template>
  <div class="full-width">
    <div class="row pad-top-extra">
      <div>* You can rename the files as well as drag each item into a specific order.</div>
    </div>

    <q-list>
      <draggable handle=".drag-handle-item" v-model="files">
        <q-item v-for="file in media" :key="file.uid" class="q-my-md">
          <q-item-section avatar class="col-1 drag-handle-item cursor-pointer">
            <q-icon name="drag_indicator"/>
          </q-item-section>
          <q-item-section class="col-9 cursor-pointer primaryText-text">
            <q-input @change="file.name = $event.target.value" :value="file.name" :rules="formRules.name" label="Display Name"  class="q-pb-xs" />
            <div class="text-caption">{{ file.settings && file.settings.originalFileName ? `Original File Name: ${file.settings.originalFileName}` : '' }}</div>
          </q-item-section>
          <q-item-section side class="primaryText-text">
            <q-btn flat round icon="delete" @click="removeFileFromModule(file)"/>
          </q-item-section>
        </q-item>
      </draggable>
    </q-list>

  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: ['media', 'quizzes'],
  components: { draggable },
  data () {
    return {
    }
  },
  computed: {
    formRules () {
      return {
        name: [ this.$formRules.required('Name') ]
      }
    },
    files: {
      get () {
        return this.media
      },
      set (data) {
        _.forEach(data, (media, i) => {
          if (!_.isEmpty(this.media[i])) {
            media.display_order = i + 1
          }
        })
        this.$emit('update:media', data)
      }
    }
  },
  methods: {
    removeFileFromModule (file) {
      if (this.quizzes.some(quiz => quiz.selectedMedia === file.uid)) {
        this.$failureMsg('Please delete or reassign the quiz attached to this asset before deleting')
        return
      }

      this.$q.dialog({
        title: 'Warning',
        message: 'Are you sure you want to delete this file from the module?',
        ok: 'Delete',
        cancel: 'Cancel',
        preventClose: true
      }).onOk(() => {
        this.$emit('update:media', _.remove(this.media, (tFile) => {
          return tFile.uid !== file.uid
        }))
      })
    }
  }
}
</script>

<style>

</style>
