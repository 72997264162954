<template>
  <div class="full-width">
    <div class="row full-width q-mb-md">
      <div class="col-12">
        <div class="text-h6">Module Banner* <span class="text-caption">(Aspect Ratio: 16/9)</span></div>
      </div>
      <q-img :src="banner_path" style="width: 250px; height: 140px;" placeholder-src="/statics/general/emptyimage.jpg"/>

      <q-btn flat @click="openVideoSelector('banner')">From Video</q-btn>
      <q-btn flat @click="openLibrary('banner')">From Library</q-btn>
      <q-btn flat @click="openInput('banner')">Custom</q-btn>
      <input type="file" @change="imageSelected()" id="bannerCustom" style="display: none"/>
    </div>

    <div id="cropper" style="display: none">
      <div class="row">
        <div class="col-12 center" id="uploaderImageCropperContainer">
          <img ref="editImage" id="uploadedImageCropper" src="" />
        </div>
      </div>

      <div class="row justify-center gutter-sm q-pt-lg">
        <div>
          <q-btn @click="imageSelectedCropper()" class="button-custom-color btn-modal-finish" color="primary">Select</q-btn>
        </div>
        <div>
          <q-btn class="button-custom-color btn-modal-finish" @click="cropperDisplay('none')" flat>Close</q-btn>
        </div>
      </div>
    </div>

    <div class="row full-width q-my-md">
      <div class="col-12">
        <div class="text-h6">Module Thumbnail* <span class="text-caption">(Actual Size: 250x140)</span></div> <!-- Maybe put option for same as banner here -->
      </div>
      <q-img :src="thumbnail_path" style="width: 250px; height: 140px;" placeholder-src="/statics/general/emptyimage.jpg"/>

      <q-btn flat @click="openVideoSelector('thumbnail')">From Video</q-btn>
      <q-btn flat @click="openLibrary('thumbnail')">From Library</q-btn>
      <q-btn flat @click="openInput('thumbnail')">Custom</q-btn>
      <input type="file" @change="imageSelected()" id="thumbnailCustom"  style="display: none"/>
    </div>

    <div class="row full-width q-my-md q-gutter-lg">
      <q-checkbox v-if="bannerIsBrandNew" :value="reuse_banner" @input="$emit('update:reuse_banner', $event)" label="Save banner for reuse" />
      <q-checkbox v-if="thumbnailIsBrandNew" :value="reuse_thumbnail" @input="$emit('update:reuse_thumbnail', $event)" label="Save thumbnail for reuse" />
    </div>

    <div class="row full-width">
      <div class="col-8 q-my-md">
        <q-input label="Title*" @change="updateProp('title', $event)" :value="title" :rules="formRules.title" />
      </div>
      <div class="col-8 q-my-md">
        <q-input label="Description*" autogrow @change="updateProp('description', $event)" :value="description" :rules="formRules.description" />
      </div>
      <div class="col-8 q-my-md">
        <VendorSelect class="full-width" @input="$emit('update:vendor_uid', $event ? $event.uid : null)" :value="vendor_uid" />
      </div>
      <div class="col-8 q-my-md">
        <div id="categorySB">
          <div class="text-caption">Categories*</div>
          <q-chip v-for="cat in categories" :key="cat.value" :label="cat.name" removable @remove="removeCategory(cat)"/>
          <q-btn color="primary" icon-right="add" rounded label="Add Category" @click="showCategoryModal = true" />
        </div>
        <div id="categorySBError" class="form-error-msg hidden text-negative pad-top-extra">A category must be selected.</div>
      </div>
      <div class="col-8 q-my-md">
        <q-input @click="() => $refs.qDateProxy.show()" mask="##/##/####" fill-mask filled label="New Until" :value="new_until">
          <template v-slot:prepend>
            <q-icon name="event" class="cursor-pointer primaryText-text">
              <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                <q-date today-btn @input="formatInputDate" :value="new_until" mask="MM/DD/YYYY" class="bg-lmsBackground text-primary" />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-8 q-my-md">
        <q-input label="Tags (Separate each tag with a comma)" @change="addTags" :value="tags.join(',')"/>
      </div>
    </div>

    <q-dialog v-model="selectVideoImage" persistent>
      <q-card style="width: 560px;" class="bg-lmsBackground primaryText-text">
        <q-card-section v-if="!notCompat && media.length > 0">Select Video
          <q-select v-model="videoSelected" :options="videoMediaOptions" id="videoFileSelector" class="select-inputs-styles border-radius-5" popup-content-class="bg-lmsBackground primaryText-text" />
          <div v-show="showVideoPlayer" class="full-width center">
            <video id="video1" crossorigin="anonymous" controls class="azuremediaplayer amp-default-skin amp-big-play-centered" tabindex="0" data-setup='{ "controls": true, "autoplay": false }'></video>
          </div>
          <canvas id="canvas1" crossorigin="anonymous" width="1980" height="1114"></canvas>
        </q-card-section>
        <q-card-section v-else>
          <div v-show="media.length === 0">No video files were uploaded.</div>
          <div v-show="notCompat && media.length > 0">This feature is not compatiable with Internet Explorer 11 and below. You can use Edge or another modern browser to access this feature.</div>
        </q-card-section>
        <q-card-section class="row justify-center gutter-sm q-pt-lg">
          <div v-if="!notCompat">
            <q-btn @click="snap()" class="button-custom-color btn-modal-finish" color="primary">Select</q-btn>
          </div>
          <div>
            <q-btn class="button-custom-color btn-modal-finish" @click="closeVideoImageSelector" flat>Close</q-btn>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showMediaLibrary" full-height>
      <StoredImageSelect :type="whichType" @imageSelected="selectStoredImage" />
    </q-dialog>

    <q-dialog v-model="showCategoryModal" full-width full-height>
      <CategoryBrowse @selectedCategory="addCategory" @closeModal="showCategoryModal = false" />
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cropper from 'cropperjs'
import { QSpinnerGears, date } from 'quasar'
import CategoryBrowse from 'components/CategoryBrowse'
import VendorSelect from 'components/VendorSelect'
import StoredImageSelect from 'components/StoredImageSelect'

export default {
  props: ['title', 'banner', 'thumbnail', 'description', 'tags', 'thumbnail_path', 'reuse_thumbnail', 'banner_path', 'reuse_banner', 'new_until', 'vendor_uid', 'categories', 'department_uids', 'media'],
  components: { CategoryBrowse, VendorSelect, StoredImageSelect },
  data () {
    return {
      showCategoryModal: false,
      bannerIsBrandNew: false,
      thumbnailIsBrandNew: false,
      showMediaLibrary: false,
      selectVideoImage: false,
      selectingImage: false,
      notCompat: false,
      videoSelected: null,
      azurePlayer: null,
      whichType: 'banner',
      selectedTab: '',
      savedImages: [],
      savedImagesPage: 0,
      savedImagesPages: 0,
      saveThumbnail: false,
      showVideoPlayer: false,
      availableCategories: [],

      saveBanner: false,
      showModal: false,
      normalizer (node) {
        return {
          id: node.uid,
          label: node.name,
          children: node.children
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      departmentsList: 'departments/list'
    }),
    formRules () {
      return {
        title: [ this.$formRules.required('Title') ],
        description: [ this.$formRules.required('Description') ]
      }
    },
    videoMediaOptions () {
      return this.media.filter(file => {
        return file.type.includes('video')
      }).map((file, i) => {
        file.label = file.name || `Video ${i + 1}`
        file.value = file.uid
        return file
      })
    }
  },
  watch: {
    videoSelected (video) {
      if (video === null) {
        this.azurePlayer.dispose()
        this.showVideoPlayer = false
        this.selectVideoImage = false
        return
      }

      let tVideo = _.find(this.videoMediaOptions, { 'value': video.uid })
      if (tVideo) {
        this.azurePlayer = amp('video1', {
          autoplay: false,
          controls: true,
          logo: { 'enabled': false },
          src: ''
        })
        this.azurePlayer.src([{ src: (tVideo.url !== undefined) ? tVideo.url : tVideo.location_url, type: 'video/mp4' }])
        this.showVideoPlayer = true
      }
    }
  },
  methods: {
    addCategory (category) {
      let index = this.categories.findIndex(cat => cat.value === category.uid)
      if (index === -1) {
        this.categories.push({ value: category.uid, name: category.name })
        this.$emit('update:categories', this.categories)
      }
      this.showCategoryModal = false
    },
    removeCategory (category) {
      let index = this.categories.findIndex(cat => cat.value === category.value)
      this.categories.splice(index, 1)
      this.$emit('update:categories', this.categories)
    },
    selectStoredImage (image) {
      this.$emit(`update:${image.usage}_path`, image.path)
      this.showMediaLibrary = false
    },
    openLibrary (which) {
      // pop modal
      this.whichType = which
      this.showMediaLibrary = true
    },
    openVideoSelector (which) {
      this.selectVideoImage = true
      this.whichType = which
    },
    cropperDisplay (display) {
      document.getElementById('cropper').style.display = display
    },
    openInput (inputId) {
      this.whichType = inputId
      document.getElementById(`${inputId}Custom`).click()
    },
    selectDepartment (event) {
      let dUids = []

      event.forEach(value => {
        if (value.value) {
          dUids.push(value.value)
        } else {
          dUids.push(value)
        }
      })

      this.updateProp('department_uids', { target: { value: dUids } })
    },
    addTags (e) {
      // the filter below only adds tags that have an actual value
      let trimmedTags = e.target.value.split(',').map(tag => tag.trim())
      let tagsWithValue = trimmedTags.filter(tag => tag.length > 0)
      this.updateProp('tags', { target: { value: tagsWithValue } })
    },
    formatInputDate (value) {
      let timestamp = new Date(value)
      let formattedDate = date.formatDate(timestamp, 'MM/DD/YYYY hh:mm a')

      this.updateProp('new_until', { target: { value: formattedDate } })

      this.$refs.qDateProxy.hide()
    },
    updateProp ($prop, $event) {
      this.$emit('update:' + $prop, $event.target.value)
    },
    updateBlob (blob) {
      if (this.whichType === 'banner') {
        this.saveBanner = false
        this.bannerIsBrandNew = true
      } else if (this.whichType === 'thumbnail') {
        this.saveThumbnail = false
        this.thumbnailIsBrandNew = true
      }

      let URL = window.URL || window.webkitURL
      let tURL = URL.createObjectURL(blob)

      if (this.whichType === 'banner') {
        this.$emit('update:banner_path', tURL)
        this.$emit('update:banner', blob)
      } else if (this.whichType === 'thumbnail') {
        this.$emit('update:thumbnail_path', tURL)
        this.$emit('update:thumbnail', blob)
      }

      this.videoSelected = null
    },
    closeVideoImageSelector () {
      this.videoSelected = null
      this.selectVideoImage = false
    },
    imageSelected () {
      this.cropperDisplay('block')
      let tFile = document.getElementById(`${this.whichType}Custom`).files[0]
      let URL = window.URL || window.webkitURL
      let tURL = URL.createObjectURL(tFile)

      document.querySelector('#uploadedImageCropper').src = tURL
      document.querySelector('#uploaderImageCropperContainer').style.height = (window.outerHeight * 0.5) + 'px'
      if (!this.$refs.editImage) {
        return
      }
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = false
      }
      let cropper = new Cropper(this.$refs.editImage, {
        aspectRatio: 16 / 9,
        viewMode: 2,
        responsive: true,
        autoCropArea: 1,
        dragMode: 'move'
      })
      this.cropper = cropper
    },
    imageSelectedCropper () {
      this.cropperDisplay('none')
      if (this.whichType === 'banner') {
        this.saveBanner = false
      } else if (this.whichType === 'thumbnail') {
        this.saveThumbnail = false
      }

      let blob = this.cropper.getCroppedCanvas()
      var uA = window.navigator.userAgent
      var isIE = /msie\s|trident\/|edge\/\d./i.test(uA)
      if (isIE) {
        this.updateBlob(blob.msToBlob())
      } else {
        blob.toBlob((blob) => {
          this.updateBlob(blob)
        }, 'image/jpeg', 1)
      }
      // this.$refs.thumbnailModal.hide()
    },
    selectSavedImage (image, index) {
      let fe = document.querySelectorAll('.selected-image')
      _.forEach(fe, (value, key) => {
        value.classList.remove('selected-image')
      })

      if (this.whichType === 'banner') {
        this.$emit('update:banner_path', image.path)
        document.querySelector('#basicBanner').src = this.banner_path
      } else if (this.whichType === 'thumbnail') {
        this.$emit('update:thumbnail_path', image.path)
        document.querySelector('#basicThumbnail').src = this.thumbnail_path
      }

      document.querySelector('#storedImage' + index).classList.add('selected-image')
      this.$refs.thumbnailModal.hide()
    },
    deleteSavedImage (image) {
      this.$q.loading.show({
        delay: 0,
        spinner: QSpinnerGears,
        message: 'Removing File From Library',
        messageColor: 'red',
        spinnerSize: 250, // in pixels
        spinnerColor: 'white',
        customClass: ['lightInnerLoader']
      })
      this.$store.dispatch('modules/deleteStoredFile', image).then(() => {
        this.$store.dispatch('modules/getStoredFiles', {
          type: this.whichType,
          page: this.savedImagesPage
        }).then((resp) => {
          this.savedImagesPages = resp.pages
          this.savedImagesPage = this.savedImagesPage
          this.savedImages = resp.images
          this.$q.loading.hide()
        })
      })
    },
    snap () {
      var $canvas = document.querySelector('#canvas1')
      if (!$canvas) {
        this.$failureMsg(`Sorry, couldn't get the image`)
        return
      }
      var $context = $canvas.getContext('2d')
      var $video = document.querySelector('#video1 video')
      if (!$video) {
        this.$failureMsg('Please select a video')
        return
      }
      $context.drawImage($video, 0, 0, $canvas.width, $canvas.height)

      var uA = window.navigator.userAgent
      var isIE = /msie\s|trident\/|edge\/\d./i.test(uA)

      if (isIE) {
        this.updateBlob($canvas.msToBlob())
      } else {
        $canvas.toBlob((blob) => {
          this.updateBlob(blob)
        }, 'image/jpeg', 1)
      }
    }
  },
  mounted () {
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
      this.notCompat = true
    }
  },
  created () {
    let cropperCSS = document.createElement('link')
    cropperCSS.setAttribute('href', 'https://cdn.jsdelivr.net/npm/cropperjs/dist/cropper.css')
    cropperCSS.setAttribute('rel', 'stylesheet')
    document.head.appendChild(cropperCSS)

    let ampCSS = document.createElement('link')
    ampCSS.setAttribute('href', 'https://amp.azure.net/libs/amp/2.1.5/skins/amp-default/azuremediaplayer.min.css')
    ampCSS.setAttribute('rel', 'stylesheet')
    document.head.appendChild(ampCSS)

    let ampScript = document.createElement('script')
    ampScript.setAttribute('src', 'https://amp.azure.net/libs/amp/2.1.5/azuremediaplayer.min.js')
    document.head.appendChild(ampScript)

    this.$store.dispatch('categories/fetch', 'edit').then((resp) => {
      if (this.$store.getters['auth/company'].name === 'Mid-States') {
        this.availableCategories = resp.map(cat => {
          return { value: cat.uid, name: cat.name }
        })
      } else {
        this.availableCategories = resp.filter(function (category) {
          return category.uid !== 'midstates1' && category.uid !== 'lms-training'
        }).map(cat => {
          return { value: cat.uid, name: cat.name }
        })
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    console.log('moving to next')
  }
}
</script>

<style lang="stylus" scoped>
.delete-icon
  top 10%
  right 10%
  position absolute
.tags-input-default-class
  input
    height 32px
.selected-image
  border 7px solid #BF2E1B
#basicBanner, #basicThumbnail
  width 350px
  height 197px
.stored-file-sizing
  width 275px
  height auto
  max-width 100%
  max-height 100%
.q-gallery
  padding-top 40px
  :nth-last-child(1)
    margin-right 0px

#canvas1
  display none

#avatarModal img
  max-height 50%
  max-width 100%

.container-width
  width 800px

.btn-modal-finish
  margin-top 20px
.lightModal
  .q-tabs-head
    background #BF2E1B !important
.darkModal
  background-color #1b1a1a !important
  color white !important
  .q-tab-pane
    border 0px !important
  a
  .prefix-custom-color
  .button-custom-color
  .q-checkbox-checked
  .icon-btn-color
  .hover-color
    color #FF7B39
  .button-custom-color
    background #323232 !important
  .q-tabs-head
    background #222 !important

.cropper-container
  margin 0 auto
  width 100% !important
  margin-top 20px
  margin-bottom 20px
#videoFileSelector
  border 1px solid black
  padding 5px
  width 30%
  margin 0 auto
  margin-bottom 15px
.videoFileSelectorWhite
  border 1px solid white !important
  .q-input-target
    color white !important
#video1
  height 300px !important
  width 100% !important
  font-size 800px !important

.azuremediaplayer
  margin 0 auto !important

.vjs-control-bar
  opacity 1 !important
</style>
