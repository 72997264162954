<template>
  <div class="full-width q-pt-md">
    <!-- quiz list -->
    <div class="row justify-center full-width">
      <q-list class="full-width q-my-lg">
        <q-item clickable @click="selectQuiz(i)" v-for="(quiz, i) in quizzes" :key="quiz + i" class="full-width q-mb-lg">
          <q-item-section>{{`Quiz for ${getQuizParent(quiz)}`}}</q-item-section>
        </q-item>
      </q-list>
    </div>

    <!-- add quiz button -->
    <div class="row justify-center">
      <div class="col-6">
        <q-btn @click="openNewQuiz" class="full-width" color="primary">
          Add Quiz
        </q-btn>
      </div>
    </div>

    <!-- quiz modal -->
    <q-dialog v-model="quizDialogOpened" maximized>
      <q-card class="q-pa-xs bg-lmsBackground primaryText-text" >
        <div class="row justify-end">
          <q-btn dense flat icon="close" style="z-index: 2" @click="resetSelectedQuiz">
            <q-tooltip>Close</q-tooltip>
          </q-btn>
        </div>
        <q-card-section class="row justify-around q-gutter-md q-mb-lg">
          <div class="text-h5 col-12">Your Quiz</div>
          <q-select v-model="selectedQuiz.selectedMedia" emit-value map-options :options="mediaOptions" label="Attached To" class="col-2" popup-content-class="bg-lmsBackground primaryText-text" />
          <q-input v-model="selectedQuiz.settings.requiredPassingPercentage" type="number" label="Required Passing Percentage" class="col-2" min="0" max="100"/>
          <q-select v-model="selectedQuiz.settings.tryAgainMethod" emit-value map-options :options="tryAgainOptions" label="Try Again Method" class="col-2" popup-content-class="bg-lmsBackground primaryText-text" />
          <q-checkbox v-model="selectedQuiz.settings.randomizeQuestions" label="Randomize Question Order" class="col-2"/>
        </q-card-section>
        <q-card-section class="row justify-between q-px-xl">
          <q-btn flat @click="deleteQuiz">Delete Quiz</q-btn>
          <q-btn label="Save" type="submit" color="primary" @click="resetSelectedQuiz" />
        </q-card-section>
        <q-separator inset/>
        <q-card-section style="height: 100%">
          <q-splitter v-model="quizSplitter1" style="height: 100%">
            <template v-slot:before>
              <!-- question list -->
              <div class="q-px-md">
                <div class="row justify-between">
                  <div class="text-h5">Questions</div>
                  <q-btn flat @click="addNewQuestion">Add</q-btn>
                </div>
                <div class="row justify-center full-width">
                  <q-list class="full-width q-my-lg">
                    <q-item clickable @click="selectQuestion(i)" v-for="(question, i) in selectedQuiz.test" :key="question + i" class="full-width q-mb-lg" :active="selectedQuestionIndex === i" active-class="selectedItem">
                      <q-item-section>{{question.content || `Question ${i + 1}`}}</q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
            </template>

            <template v-slot:after>
              <q-splitter v-model="quizSplitter2" style="height: 100%">
                <template v-slot:before>
                  <!-- question info and answers list -->
                  <div class="q-px-md" v-if="selectedQuestion !== null">
                    <div class="row justify-between">
                      <div class="text-h5">Question Info</div>
                      <q-btn flat @click="deleteQuestion">Delete</q-btn>
                    </div>
                    <div class="row q-my-lg">
                      <div class="col-12">
                        <q-input v-model="selectedQuestion.content" label="Question" autogrow />
                      </div>
                    </div>
                    <div class="row q-my-lg justify-between">
                      <div class="text-h5">Answers</div>
                      <q-btn flat @click="addNewAnswer">Add</q-btn>
                    </div>
                    <div class="row justify-center full-width q-my-lg">
                      <q-list class="full-width">
                        <q-item clickable @click="selectAnswer(i)" v-for="(answer, i) in selectedQuestion.answers" :key="answer + i" class="full-width row items-center q-mb-lg"  :active="selectedAnswerIndex === i" active-class="selectedItem">
                          <q-item-section avatar>
                            <q-icon :name="answer.isChecked ? 'check_circle' : 'cancel'" :color="answer.isChecked ? 'green' : 'red'" />
                          </q-item-section>
                          <q-item-section>{{answer.answer || `Answer ${i + 1}`}}</q-item-section>
                        </q-item>
                      </q-list>
                    </div>
                  </div>
                </template>

                <template v-slot:after>
                  <!-- answer info -->
                  <div class="q-px-md" v-if="selectedQuestion !== null">
                    <div class="row justify-between">
                      <div class="text-h5">Answer Info</div>
                      <q-btn flat @click="deleteAnswer" v-if="selectedQuestion && selectedQuestion.answers && selectedQuestion.answers.length > 1">Delete</q-btn>
                    </div>
                    <div class="row q-my-lg">
                      <div class="col-12">
                        <q-checkbox v-model="selectedAnswer.isChecked" label="Correct Answer" />
                      </div>
                      <div class="col-12">
                        <q-input v-model="selectedAnswer.answer" label="Answer" autogrow />
                      </div>
                      <div class="col-12 q-my-lg">
                        <q-input v-model="selectedAnswer.explanation" label="Explanation" autogrow />
                      </div>
                    </div>
                  </div>
                </template>
              </q-splitter>
            </template>
          </q-splitter>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>

export default {
  props: ['quizzes', 'quiz', 'media'],
  data () {
    return {
      selectedQuiz: {
        selectedMedia: 'Module',
        settings: {
          requiredPassingPercentage: '70',
          tryAgainMethod: '3',
          randomizeQuestions: false
        },
        test: []
      },
      selectedQuizIndex: null,
      selectedQuestion: {},
      selectedQuestionIndex: null,
      selectedAnswer: {},
      selectedAnswerIndex: null,
      quizDialogOpened: false,
      quizSplitter1: 33,
      quizSplitter2: 66,
      users: [],
      // advocates: [],
      mediaOptions: [
        { label: 'Module', value: 'Module' }
      ],
      tryAgainOptions: [
        {
          label: 'Make Corrections',
          value: '0'
        },
        {
          label: 'One Try',
          value: '1'
        },
        {
          label: 'Two Tries',
          value: '2'
        },
        {
          label: 'Three Tries',
          value: '3'
        }
      ],
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }]
          ]
        }
      }
    }
  },
  computed: {
    enabled: {
      get () {
        return (this.quizzes.length > 0)
      },
      set (value) {
        if (value) {
          this.$emit('update:quizzes', [{
            'selectedMedia': 'Module',
            'settings': {
              'requiredPassingPercentage': '70',
              'tryAgainMethod': '3',
              'randomizeQuestions': false
            },
            'test': []
          }])
        } else {
          this.$emit('update:quizzes', [])
        }
      }
    }
  },
  watch: {
    media: function (media) {
      this.mediaOptions = [{ label: 'Module', value: 'Module' }]
      _.forEach(media, (file, index) => {
        if (file.type.includes('video')) {
          this.mediaOptions.push({
            label: file.name || `Video ${index + 1}`,
            value: file.uid
          })
        }
      })
    },
    'selectedQuiz.settings.requiredPassingPercentage': function (val) {
      let num = parseInt(val)
      if (num < 0) {
        this.selectedQuiz.settings.requiredPassingPercentage = '0'
      }
      if (num > 100) {
        this.selectedQuiz.settings.requiredPassingPercentage = '100'
      }
    }
  },
  methods: {
    getQuizParent (quiz) {
      if (quiz.selectedMedia === 'Module' || quiz.selectedMedia === null) {
        return 'Module'
      }

      return this.media.find(video => video.uid === quiz.selectedMedia).name || 'Video'
    },
    addMediaOptions () {
      this.media.forEach((video, i) => {
        this.mediaOptions.push({
          label: video.name || `Video ${i + 1}`,
          value: video.uid
        })
      })
    },
    resetSelectedQuiz () {
      this.selectedQuiz = {
        selectedMedia: 'Module',
        settings: {
          requiredPassingPercentage: '70',
          tryAgainMethod: '3',
          randomizeQuestions: false
        },
        test: []
      }
      this.selectedQuizIndex = null
      this.quizDialogOpened = false
    },
    selectQuiz (index) {
      this.selectedQuiz = this.quizzes[index]
      // selected quiz index??
      this.selectedQuizIndex = index
      this.quizDialogOpened = true
      this.selectQuestion(0)
    },
    selectQuestion (index) {
      if (index > -1 && this.selectedQuiz.test[index]) {
        this.selectedQuestion = this.selectedQuiz.test[index]
        this.selectedQuestionIndex = index
        this.selectedAnswer = this.selectedQuestion.answers[0]
        this.selectedAnswerIndex = 0
      }
    },
    selectAnswer (index) {
      this.selectedAnswer = this.selectedQuestion.answers[index]
      this.selectedAnswerIndex = index
    },
    openNewQuiz () {
      this.quizzes.push(this.selectedQuiz)
      this.selectedQuizIndex = this.quizzes.length - 1
      this.quizDialogOpened = true
      this.addNewQuestion()
    },
    saveQuiz () {
      this.quizzes.push(this.selectedQuiz)
      this.resetSelectedQuiz()
    },
    addQuiz () {
      if (this.quizzes.length === this.mediaOptions.length) {
        this.$failureMsg('Max quiz count for this module reached. Only one quiz per video file and one general module quiz allowed')
        return
      }

      this.$emit('update:quizzes', this.quizzes.concat({
        'selectedMedia': 'Module',
        'settings': {
          'requiredPassingPercentage': '70',
          'tryAgainMethod': '3',
          'randomizeQuestions': false
        },
        'test': []
      }))
      this.$scrollToElement('#q-app')
    },
    addNewQuestion () {
      this.selectedQuiz.test.push({
        content: '',
        answers: [
          {
            isChecked: false,
            answer: '',
            explanation: ''
          },
          {
            isChecked: false,
            answer: '',
            explanation: ''
          }
        ]
      })
      this.selectedQuestion = this.selectedQuiz.test[this.selectedQuiz.test.length - 1] // we want to automatically select the most recent questions
      this.selectedQuestionIndex = this.selectedQuiz.test.length - 1
      this.selectedAnswer = this.selectedQuestion.answers[0] // we want to automatically select first answer of new question
      this.selectedAnswerIndex = 0
    },
    addNewAnswer () {
      this.selectedQuestion.answers.push({
        isChecked: false,
        answer: '',
        explanation: ''
      })
      this.selectedAnswer = this.selectedQuestion.answers[this.selectedQuestion.answers.length - 1]
      this.selectedAnswerIndex = this.selectedQuestion.answers.length - 1
    },
    deleteQuiz () {
      this.quizzes.splice(this.selectedQuizIndex, 1)
      this.resetSelectedQuiz()
    },
    deleteQuestion () {
      this.selectedQuiz.test.splice(this.selectedQuestionIndex, 1)
      if (this.selectedQuiz.test.length > 0) {
        this.selectQuestion(0)
      } else {
        this.selectedQuestion = null
      }
    },
    deleteAnswer () {
      this.selectedQuestion.answers.splice(this.selectedAnswerIndex, 1)
      this.selectAnswer(0)
    }
  },
  async created () {
    this.addMediaOptions()
  }
}
</script>

<style>

</style>
