<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between items-center">
        <div class="text-h4">Modules</div>
        <q-btn v-if="which === 'edit' && module.uid" outline color="primary" @click="viewModule">View Module</q-btn>
      </div>

      <div class="row q-gutter-xl" v-if="!moduleSelected">
        <q-btn @click="setAction('new')" color="primary">New Module</q-btn>
        <q-btn @click="setAction('import')" color="primary">Import Module</q-btn>
        <q-btn @click="setAction('edit')" color="primary">Edit Existing Module</q-btn>
      </div>

      <div class="row q-mt-lg" v-if="showEditModule && !moduleSelected">
        <div class="text-subtitle1">Search for a module to {{ importModule ? 'Import' : 'Edit' }}</div>
      </div>

      <div v-if="showEditModule && !moduleSelected">
        <div class="row full-width items-end q-py-xs">
          <q-btn v-if="which === 'edit'" color="primary" @click="showCategoryModal = true">Find By Category</q-btn>
          <CategorySelect v-else class="full-width" style="max-width: 300px" @input="getModules" v-bind:selection.sync="categoryUid" :categoryOptions="importModule ? globalCategoryOptions : categories" :label="importModule ? 'Select' : 'Select Category'" idValue="uid" labelValue="name" />
          <q-input v-model="searchText" @input="getModules" label="Search for a Specific Module" class="q-ml-xl" style="width: 300px">
            <template v-slot:prepend>
              <q-icon name="search" class="primaryText-text" />
            </template>
          </q-input>
        </div>
        <div v-if="chosenCategory && which === 'edit'" class="row full-width">
          Showing Modules in the {{ chosenCategory.name }} category
        </div>
        <div v-if="modules.length > 0" class="row full-width justify-between">
          <div class="col-xs-12 col-md-3 q-pa-sm">
            <q-select v-model="sortBy" :options="sortByOptions" label="Sort By" popup-content-class="bg-lmsBackground primaryText-text" />
          </div>
          <q-select v-model="resultsPerPage" :options="resultsPerPageOptions" label="Results Per Page" style="width: 170px" popup-content-class="bg-lmsBackground primaryText-text">
          </q-select>
        </div>
        <div v-if="modules.length === 0 && which === 'import' && searchText === '' && !categoryUid">
          <div class="row full-width">
              <div id="files" class="col-12 row items-center">
                <file-upload
                  class="btn btn-primary file-upload-container"
                  :custom-action="uploadZip"
                  :headers="{'Authorization': 'Bearer '+this.$store.getters['auth/accessToken']}"
                  :multiple="true"
                  :drop="true"
                  :drop-directory="false"
                  :thread="2"
                  v-model="files"
                  :extensions="/\.(gif|jpe?g|png|txt|text|pdf|mp4|mp3|zip|doc|m2v|mov|docx|xls|xlsx|ppt|pptx|qt)$/i"
                  ref="upload">
                  <q-icon name="cloud_upload" class="items-center dark-theme-text-default light-theme-text-default" size="18rem"></q-icon>
                </file-upload>

                <div class="row full-width justify-center" v-if="files.length">
                  <q-list bordered separator class="self-center" style="width: 500px">
                    <q-item v-for="(file, i) in files" :key="file.id" class="row">
                      <q-item-section class="items-start col-8">{{file.name}}</q-item-section>
                      <q-item-section class="col-3">
                        <q-btn flat @click="files.splice(i, 1)">Remove</q-btn>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>

                <div @click.prevent="selectFileUpload" v-else class="row full-width justify-center q-my-md">
                  <div class="text-h5">Drop zip file to import and create new module</div>
                </div>

                <div class="row full-width justify-center q-my-md">
                  <div @click.prevent="selectFileUpload" v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                    <h3>Drop zip file to upload</h3>
                    </div>
                  <div v-show="$refs.upload && !$refs.upload.dropActive" @click.prevent="selectFileUpload"><i class="fa fa-plus"></i>Select files</div>
                </div>
              </div>
            </div>

            <div class="row full-width justify-center q-my-lg" v-if="files.length">
              <q-btn id="startUploadBtn" color="primary" @click.prevent="$refs.upload.active = true" v-if="!$refs.upload || !$refs.upload.active">Start Upload</q-btn>
            </div>
        </div>
        <div class="row justify-center q-pl-md q-mt-xl">
          <div class="col-md-12">
            <div v-if="modules.length > 0" class="row q-gutter-lg" style="min-height: 234px">
                <q-card flat bordered style="min-width: 250px;" v-for="(module) in modules" :key="module.uid" class="bg-lmsBackground primaryText-text">
                  <q-img :src="module.thumbnail_path">
                    <q-chip v-if="module.hasBeenPreviouslyImported" dense color="green" text-color="white" icon="check_circle_outline" class="absolute-top-right">Previously Imported</q-chip>
                    <div class="absolute-bottom">
                      {{ module.title }}
                    </div>
                  </q-img>
                  <q-card-section>
                    <div class="text-caption text-italic text-center secondaryText-text">Last updated on {{ getDateFormat(module.updated_at) }}</div>
                    <div class="text-caption text-italic text-center secondaryText-text">Created By: {{ getMemberName(module.customer_uid) }}</div>
                  </q-card-section>
                  <q-card-section class="row justify-center">
                    <q-btn flat @click="editModule(module.uid, true)">{{importModule ? 'Import' : 'Edit'}}</q-btn>
                    <ModulePreview :moduleUid="module.uid" :mode="importModule ? 'Import' : 'Edit'" @selectModule="editModule(module.uid, true)" />
                  </q-card-section>
                </q-card>
            </div>
            <div v-if="(categoryUid || searchText) && modules.length < 1" class="text-h5 text-italic">Sorry, no modules for this category</div>
          </div>
        </div>
        <div v-if="pageMax > 1" class="row justify-center full-width q-mt-xl">
          <q-pagination v-model="page" :max="pageMax" :max-pages="5" :ellipses="false" :boundary-numbers="false" :direction-links="true">
          </q-pagination>
        </div>
      </div>

      <q-form @submit="step === 7 ? saveModule() : nextTab()" @reset="$refs.moduleStepper.previous()" autocomplete="off">
        <q-stepper v-model="step" ref="moduleStepper" animated flat header-nav v-if="moduleSelected" class="bg-lmsBackground">
          <q-step :name="1" title="Upload" icon="cloud_upload" :done="which === 'new' && step > 1" :header-nav="which !== 'new' || which === 'new' && step > 1">
            <upload-tab v-bind.sync="module" v-on:nextTab="nextTab()"></upload-tab>
          </q-step>
          <q-step :name="2" title="Module Info" icon="info" :done="which === 'new' && step > 2" :header-nav="which !== 'new' || which === 'new' && step > 2">
            <info-tab v-bind.sync="module"></info-tab>
          </q-step>
          <q-step :name="3" title="Module Assets" icon="view_list" :done="which === 'new' && step > 3" :header-nav="which !== 'new' || which === 'new' && step > 3">
            <files-tab v-bind.sync="module"></files-tab>
          </q-step>
          <q-step :name="4" title="Settings" icon="settings" :done="which === 'new' && step > 4" :header-nav="which !== 'new' || which === 'new' && step > 4">
            <settings-tab v-bind.sync="module"></settings-tab>
          </q-step>
          <q-step :name="5" title="Restrictions" icon="lock" :done="which === 'new' && step > 5" :header-nav="which !== 'new' || which === 'new' && step > 5">
            <restrictions-tab v-bind.sync="module"></restrictions-tab>
          </q-step>
          <q-step :name="6" title="Quiz" icon="assignment_turned_in" :done="which === 'new' && step > 6" :header-nav="which !== 'new' || which === 'new' && step > 6">
            <quizzes-tab v-bind.sync="module"></quizzes-tab>
          </q-step>
          <q-step :name="7" title="Certificate" icon="how_to_reg" :done="which === 'new' && step > 7" :header-nav="which !== 'new' || which === 'new' && step > 7">
            <certificates-tab v-bind.sync="module"></certificates-tab>
          </q-step>
          <template v-slot:navigation>
            <div class="row full-width justify-between">
              <q-stepper-navigation>
                <q-btn flat @click="reset" color="primary" label="Cancel" class="q-ml-sm" />
              </q-stepper-navigation>
              <q-stepper-navigation v-if="which !== 'new' && step !== 7">
                <q-btn @click="saveModule" color="primary" :label="saveButtonText" class="q-ml-sm" />
              </q-stepper-navigation>
              <q-stepper-navigation class="q-gutter-md">
                <q-btn v-if="step > 1" flat color="primary" type="reset" label="Back" />
                <q-btn type="submit" :label="step === 7 ? saveButtonText : 'Next'" color="primary"/>
              </q-stepper-navigation>
            </div>
          </template>
        </q-stepper>
      </q-form>
      <div class="row q-my-xl justify-center" v-if="which === 'edit' && moduleSelected">
        <q-btn flat @click="deleteModule(module)" style="min-width: 500px">Delete Module</q-btn>
      </div>

      <q-dialog v-model="showCategoryModal" full-width full-height>
        <CategoryBrowse @selectedCategory="chooseCategory" @closeModal="showCategoryModal = false" />
      </q-dialog>

    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { date } from 'quasar'
import UploadTab from 'components/modules/uploadTab'
import InfoTab from 'components/modules/infoTab'
import FilesTab from 'components/modules/filesTab'
import SettingsTab from 'components/modules/settingsTab'
import RestrictionsTab from 'components/modules/restrictionsTab'
import QuizzesTab from 'components/modules/quizzesTab'
import CertificatesTab from 'components/modules/certificatesTab'
import { required, minLength } from 'vuelidate/lib/validators'
import FileUpload from 'vue-upload-component'
import CategorySelect from 'components/CategorySelect'
import ModulePreview from 'components/ModulePreview'
import CategoryBrowse from 'components/CategoryBrowse'

export default {
  name: 'modulesPage',
  components: { UploadTab, InfoTab, FilesTab, SettingsTab, RestrictionsTab, QuizzesTab, CertificatesTab, FileUpload, CategorySelect, ModulePreview, CategoryBrowse },
  data () {
    return {
      showCategoryModal: false,
      files: [],
      searchText: '',
      showEditModule: false,
      which: null,
      importModule: false,
      step: 1,
      steps: {
        1: 'uploadTab',
        2: 'infoTab',
        3: 'filesTab',
        4: 'settingsTab',
        5: 'restrictions',
        6: 'quizzesTab',
        7: 'certificatesTab'
      },
      categories: [],
      globalCategoryOptions: [],
      categoryUid: null,
      modules: [],
      importedModules: [],
      page: 1,
      pageMax: 0,
      resultsPerPage: 25,
      resultsPerPageOptions: [25, 50, 100, 'View All'],
      moduleSelected: false,
      module: {
        title: '',
        points: null,
        banner: null,
        thumbnail: null,
        imported: false,
        imported_uid: null,
        description: '',
        quizzes: [],
        quiz: [],
        tags: [],
        media: [],
        banner_path: '',
        reuse_banner: false,
        thumbnail_path: '',
        reuse_thumbnail: false,
        settings: {
          comments: false,
          bypassIpRestrictions: false,
          restrictProgression: true,
          certificate: '0',
          browsable: 'true'
        },
        new_until: date.formatDate(date.addToDate(new Date(), { month: 1 }), 'MM/DD/YYYY hh:mm a'),
        is_global: false,
        browsing: 'Company',
        browsingRestrictions: {
          roles: [],
          departments: [],
          locations: []
        },
        // category: {
        //   uid: null
        // },
        categories: [],
        vendor_uid: null,
        members: [],
        chosenCategory: null
      },
      normalizer (node) {
        return {
          id: node.uid,
          label: node.name,
          children: node.children
        }
      },
      sortByOptions: [{ label: 'Oldest to Newest', value: '_created_asc' }, { label: 'Newest to Oldest', value: '_created_desc' }],
      sortBy: { label: 'Newest to Oldest', value: '_created_desc' }
    }
  },
  computed: {
    saveButtonText: function () {
      return this.which === 'edit' ? 'Save' : 'Publish'
    }
  },
  watch: {
    '$route.path': function () {
      if (this.$route.path === '/manage/modules') {
        // resetting the component state
        this.moduleSelected = false
        this.showEditModule = false
        this.categoryUid = null
        this.modules = []
        this.which = null
        this.importModule = false
        this.step = 1
        this.resetModule()
      }
    },
    page: function () {
      this.getModules()
      this.$scrollToElement('#q-app')
    },
    resultsPerPage: function () {
      this.page = 1
      this.getModules()
    },
    sortBy: function () {
      this.getModules()
    },
    modules: function () {
      if (this.modules.length > 0) {
        this.modules.forEach(module => {
          if (this.importedModules.includes(module.uid)) {
            // it has been imported
            Vue.set(module, 'hasBeenPreviouslyImported', true)
          }
        })
      }
    }
  },
  methods: {
    chooseCategory (category) {
      this.chosenCategory = category
      this.categoryUid = category.uid
      this.getModules()
      this.showCategoryModal = false
    },
    viewModule () {
      window.open(`${window.location.origin}/module/${this.module.uid}`, '_blank')
    },
    selectFileUpload () {
      document.getElementById('file').click()
    },
    async uploadZip (file, component) {
      this.$q.loading.show()
      let fd = new FormData()
      fd.append('file', file.file)

      let res = await this.$axios.post(this.$consts.IMPORT_ZIP_MODULE_URL, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
      res.data.category = { uid: null }
      res.data.settings.certificate = res.data.settings.certificate.toString()
      res.data.reuse_banner = false
      res.data.reuse_thumbnail = false
      this.module = res.data
      this.setAction('new')
      this.step = 2
      this.$q.loading.hide()
    },
    setAction (which) {
      this.which = which
      this.categoryUid = null
      this.modules = []
      this.chosenCategory = null

      switch (this.which) {
        case 'new':
          this.moduleSelected = true
          this.$router.push({ name: 'manageModulesNew' }).catch(err => err)
          break
        case 'import':
          this.importModule = true
          this.showEditModule = true
          break
        case 'edit':
          this.showEditModule = true
          this.importModule = false
          break
      }
    },
    deleteModule (module) {
      this.$q.dialog({
        title: 'Confirm',
        message: `Are you sure you want to delete ${module.title}? There will be no way to get this module back.`,
        ok: true,
        cancel: true
      }).onOk(() => {
        this.$q.loading.show()
        this.$axios.delete(this.$consts.DELETE_MODULE_URL + '/' + module.uid).then((response) => {
          this.$q.loading.hide()
          this.$successMsg('Module deleted')
          this.reset()
        }).catch(err => {
          this.$q.loading.hide()
          this.$failureMsg('This module may already be deleted please refresh the page')
          this.$log(this.$options.name, 'Could not delete module', err)
        })
      })
    },
    reset () {
      this.resetModule()
      this.$router.push({ name: 'manageModules' }).catch(err => err)
      this.moduleSelected = false

      // if they were editing/importing, they might have clicked on the wrong one and just want to go back to the results.
      // If we reset the categoryUid and modules, it would wipe out those results and they would have to drill down again
      // we only want to do this if they are coming from a new form
      if (this.which === 'new') {
        this.categoryUid = null
        this.showEditModule = false
        this.modules = []
      }

      this.which = 'new'
      this.importModule = false
      this.step = 1
    },
    resetModule () {
      this.module = {
        title: '',
        points: null,
        banner: null,
        thumbnail: null,
        imported: false,
        description: '',
        quizzes: [],
        quiz: [],
        tags: [],
        media: [],
        banner_path: '',
        reuse_banner: false,
        thumbnail_path: '',
        reuse_thumbnail: false,
        settings: {
          comments: false,
          bypassIpRestrictions: false,
          restrictProgression: false,
          certificate: 0,
          browsable: 'true'
        },
        new_until: date.formatDate(date.addToDate(new Date(), { month: 1 }), 'MM/DD/YYYY hh:mm a'),
        is_global: false,
        browsing: 'On',
        browsingRestrictions: {
          roles: [],
          departments: [],
          locations: []
        },
        // category: {
        //   uid: null
        // },
        categories: [],
        vendor_uid: null
      }
    },
    async editModule (moduleUid, changeRoute) {
      this.$q.loading.show()
      await this.$axios.get(this.$consts.GET_MODULE_EDIT_URL + '/' + moduleUid).then((response) => {
        this.module = response
      })
      if (!this.module.banner) {
        Vue.set(this.module, 'banner', null)
      }

      if (!this.module.thumbnail) {
        Vue.set(this.module, 'thumbnail', null)
      }

      if (!this.module.reuse_banner) {
        Vue.set(this.module, 'reuse_banner', false)
      }

      if (!this.module.reuse_thumbnail) {
        Vue.set(this.module, 'reuse_thumbnail', false)
      }

      this.moduleSelected = true

      this.module.new_until = date.formatDate(new Date(this.module.new_until), 'MM/DD/YYYY hh:mm a')

      if (this.importModule) {
        this.which = 'import'
        this.module.imported_uid = moduleUid
        this.module.is_global = false
        this.module.categories = []
        this.module.new_until = date.formatDate(date.addToDate(new Date(), { month: 1 }), 'MM/DD/YYYY hh:mm a')
      } else {
        this.which = 'edit'
      }

      if (changeRoute) {
        this.$router.push({ name: this.which === 'edit' ? 'manageModulesEdit' : 'manageModulesImport', params: { uid: moduleUid } }).catch(err => err)
      }

      this.step = 2
      this.$q.loading.hide()
    },
    searchForModule () {
      if (this.categoryUid) {
        this.categoryUid = null
      }
      if (!this.searchText) {
        this.modules = []
        return
      }
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: this.searchText,
          params: {
            filters: this.which === 'edit' ? `customer_uid:${this.$store.getters['auth/user'].company.uid}` : `is_global:1`
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.modules = content.results['0'].hits
      })
    },
    getModules () {
      let categoryUid = this.categoryUid === 'all' ? '' : this.categoryUid
      this.$moduleSearch.clearCache()
      if (this.which === 'edit') {
        this.$moduleSearch.search([
          {
            indexName: this.$consts.MODULES_INDEX + this.sortBy.value,
            query: this.searchText,
            params: {
              hitsPerPage: this.resultsPerPage === 'View All' ? 100000 : this.resultsPerPage,
              page: this.page - 1,
              filters: `customer_uid:${this.$store.getters['auth/user'].company.uid}`,
              facetFilters: [
                categoryUid ? `categories:${categoryUid}` : ''
                // `categories:${categoryUid}`
                // `category_uid:${categoryUid}`
              ]
            }
          }
        ], (err, content) => {
          if (err) throw err
          this.modules = content.results['0'].hits
          this.pageMax = content.results['0'].nbPages
        })
      } else {
        this.$moduleSearch.search([
          {
            indexName: this.$consts.MODULES_INDEX + this.sortBy.value,
            query: this.searchText,
            params: {
              hitsPerPage: this.resultsPerPage === 'View All' ? 100000 : this.resultsPerPage,
              page: this.page - 1,
              filters: `is_global:1`,
              facetFilters: [
                this.categoryUid === 'all' ? `customer_uid:-${this.globalCategoryOptions[0].uid}` : `customer_uid:${this.globalCategoryOptions[0].uid}`
              ]
            }
          }
        ], (err, content) => {
          if (err) throw err
          this.modules = content.results['0'].hits
          this.pageMax = content.results['0'].nbPages
        })
      }
    },
    saveModule () {
      // checking every tab to see if it's valid
      if (Object.values(this.steps).some(step => !this.tabIsValid(step))) return

      this.$q.loading.show({ message: 'Saving Module...' })
      this.orderAssets()
      let fd = new FormData()
      fd.append('title', this.module.title)
      fd.append('imported', this.which === 'import')
      fd.append('imported_uid', this.module.imported_uid)
      fd.append('points', this.module.points)
      fd.append('new_until', this.module.new_until)
      fd.append('is_global', this.module.is_global)
      fd.append('description', this.module.description)
      fd.append('vendor_uid', this.module.vendor_uid)
      // fd.append('category_uid', this.module.category.uid)
      fd.append('categories', JSON.stringify(this.module.categories.map(cat => cat.value)))
      fd.append('banner', this.module.banner)
      fd.append('thumbnail', this.module.thumbnail)
      fd.append('banner_path', this.module.banner_path)
      fd.append('reuse_banner', this.module.reuse_banner || false)
      fd.append('thumbnail_path', this.module.thumbnail_path)
      fd.append('reuse_thumbnail', this.module.reuse_thumbnail || false)
      fd.append('tags', JSON.stringify(this.module.tags))
      fd.append('media', JSON.stringify(this.module.media))
      fd.append('settings', JSON.stringify(this.module.settings))
      fd.append('browsingRestrictions', JSON.stringify(this.module.settings.browsable === 'restricted' ? this.module.browsingRestrictions : { roles: [], departments: [], locations: [] }))
      fd.append('quizzes', JSON.stringify(this.module.quizzes))

      let url = this.which === 'edit' ? this.$consts.UPDATE_MODULE_URL + '/' + this.module.uid : this.$consts.CREATE_MODULE_URL

      this.$axios.post(url, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
        this.module = response
        this.$q.loading.hide()
        this.$successMsg(`Module has been ${this.which === 'edit' ? 'saved' : 'added'}`)
        this.reset()
      }).catch((error) => {
        this.$failureMsg()
        this.$q.loading.hide()
        throw (error)
      })
    },
    nextTab () {
      // need to run validation for each step here
      if (this.tabIsValid(this.steps[this.$refs.moduleStepper.value])) {
        this.$refs.moduleStepper.next()
      }
    },
    tabIsValid (currentTab) {
      this.$v.$touch()
      if (this.$v.module[currentTab] && this.$v.module[currentTab].$invalid) {
        this.$failedValidationMsg()
        return false
      }
      return true
    },
    getDateFormat (pDate) {
      return date.formatDate(pDate, 'MM/DD/YYYY')
    },
    orderAssets () {
      // assets are initialized with a display order of '0'. They will all update if the user reorders them in the files tab. But in case they don't, we have a catch all here
      if (this.module.media.some(asset => asset.display_order === '0')) {
        this.module.media.forEach((asset, i) => {
          asset.display_order = i + 1
        })
      }
    },
    getMemberName (pUid) {
      let member = this.members.find(member => member.uid === pUid)
      return member.name || 'Not Found'
    }
  },
  validations: {
    module: {
      media: {
        required,
        minLength: minLength(1)
      },
      title: { required },
      banner_path: { required },
      thumbnail_path: { required },
      description: { required },
      categories: { required, minLength: minLength(1) },
      uploadTab: ['module.media'],
      infoTab: ['module.title', 'module.banner_path', 'module.thumbnail_path', 'module.description', 'module.categories'],
      filesTab: ['module.media']
    }
  },
  created () {
    this.$q.loading.show({ message: 'Loading...' })
    this.$store.dispatch('categories/fetch', 'edit').then((resp) => {
      if (this.$store.getters['auth/company'].name === 'Mid-States') {
        this.categories = resp
      } else {
        this.categories = resp.filter(function (category) {
          return category.uid !== 'midstates1' && category.uid !== 'lms-training'
        })
      }
      if (!this.$route.params.uid) {
        this.$q.loading.hide()
      }
    })

    this.$axios.get(this.$consts.GET_GLOBAL_MODULES_LIST_URL).then((response) => {
      let msd = response.find(cat => cat.name === 'Mid-States')
      this.globalCategoryOptions.push({ uid: msd.uid, name: 'Mid-States Content' })
      this.globalCategoryOptions.push({ uid: 'all', name: 'Other Mid-States Members Shared Content' })
    })

    this.$axios.get(this.$consts.GET_IMPORTED_MODULES_LIST_URL).then((response) => {
      this.importedModules = response.map(module => module.imported_module_uid)
    })

    if (this.$route.params.uid) {
      let moduleUid = this.$route.params.uid
      // checking if user is wanting to import or edit.
      if (this.$route.path.split('/').includes('import')) {
        this.importModule = true
      } else {
        this.setAction('edit')
      }
      this.editModule(moduleUid, false)
    }

    this.$store.dispatch('auth/getGlobalLocations').then(res => {
      this.members = res
    })
  }
}
</script>

<style>

</style>
