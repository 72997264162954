<template>
  <div class="full-width pad-top-extra">
    <div>
      <div class="row">
        <q-checkbox v-model="enabled" label="Enable Certificate" />
      </div>
    </div>
    <div v-if="enabled" class="q-my-xl">
      <div>
        <div id="certDiv" class="row pad-top-extra-2-5">
          <div class="col-sm-4 center">
            <q-radio id="testRadio" @input="$set(settings, 'certificate', $event)" :value="settings.certificate" val="1" label="Certificate 1" />
            <img src="/statics/certificates/cert-1.jpg" class="certs cursor-pointer pad-top-extra" />
          </div>
          <div class="col-sm-4 center">
            <q-radio @input="$set(settings, 'certificate', $event)" :value="settings.certificate" val="2" label="Certificate 2" />
            <img src="/statics/certificates/cert-2.jpg" class="certs cursor-pointer pad-top-extra" />
          </div>
          <div class="col-sm-4 center">
            <q-radio @input="$set(settings, 'certificate', $event)" :value="settings.certificate" val="3" label="Certificate 3" />
            <img src="/statics/certificates/cert-3.jpg" class="certs cursor-pointer pad-top-extra" />
          </div>
        </div>
        <div class="form-error-msg hidden text-negative pad-top-extra">A certificate style must be selected.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['settings'],
  computed: {
    enabled: {
      get () {
        return (this.settings.certificate !== '0')
      },
      set (value) {
        if (value) {
          this.$set(this.settings, 'certificate', '1')
        } else {
          this.$set(this.settings, 'certificate', '0')
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.certs
  margin 0 auto
  max-width 85%
  height auto
</style>
