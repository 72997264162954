<template>
  <div>
    <div class="row full-width">
      <div class="col">
        <div class="uploader-div row full-width justify-center">
          <div class="upload">
            <div class="row full-width">
              <div id="files" class="col-12 row items-center cursor-pointer">

                <file-upload
                  class="btn btn-primary file-upload-container cursor-pointer"
                  :post-action="postURL"
                  :headers="{'Authorization': 'Bearer '+this.$store.getters['auth/accessToken']}"
                  :multiple="true"
                  :drop="true"
                  :drop-directory="false"
                  :thread="2"
                  v-model="files"
                  :extensions="/\.(gif|jpe?g|png|txt|text|pdf|mp4|mp3|zip|doc|m2v|mov|docx|xls|xlsx|ppt|pptx|qt)$/i"
                  ref="upload">
                  <q-icon name="cloud_upload" class="items-center dark-theme-text-default light-theme-text-default cursor-pointer" size="18rem"></q-icon>
                </file-upload>

                <div class="row full-width justify-center" v-if="files.length">
                  <q-list bordered separator class="self-center" style="width: 500px">
                    <q-item v-for="(file, i) in files" :key="file.id" class="row">
                      <q-item-section class="items-start col-8">{{file.name}}</q-item-section>
                      <!-- <q-item-section class="col-1">0%</q-item-section> -->
                      <q-item-section class="col-3">
                        <q-btn flat @click="files.splice(i, 1)">Remove</q-btn>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>

                <div @click.prevent="selectFileUpload" v-else class="row full-width justify-center q-my-md">
                  <!-- <div class="text-h5">Drop files anywhere or click select files to upload</div> -->
                  <div class="text-h5">Click anywhere to upload files or drag and drop your files on the page</div>
                </div>

                <div class="row full-width justify-center q-my-md">
                  <div @click.prevent="selectFileUpload" v-show="$refs.upload && $refs.upload.dropActive" class="drop-active"><h3>Drop files to upload</h3></div>
                  <!-- <div @click.prevent="selectFileUpload"><i class="fa fa-plus"></i>Select files</div> -->
                </div>
                <div class="row full-width justify-center q-mb-md">
                  <div @click.prevent="selectFileUpload" class="text-caption">Max Size - 1GB*</div>
                </div>
              </div>
            </div>

            <div class="row q-my-lg" v-if="files.length">
              <div class="col">
                <div v-if="!exceededFileSize">
                  <q-btn id="startUploadBtn" color="primary" :disable="uploadAllowed" @click.prevent="startUpload" v-if="!$refs.upload || !$refs.upload.active">Start Upload</q-btn>
                  <div id="filesError" class="form-error-msg hidden text-negative pad-top-extra">There must be files assigned to this module.</div>
                </div>
                <div v-else class="row justify-center q-my-lg">
                  <q-banner rounded class="bg-negative text-white" style="max-width: 350px">
                    <template v-slot:avatar>
                      <q-icon name="error" />
                    </template>
                    One or more of your files exceeds our max size of 1GB
                  </q-banner>
                </div>
                <br />
                <br />
              </div>
            </div>

            <div class="row full-width justify-center">
              <q-btn color="primary" outline label="Add Embedded Content" @click="showEmbeddedModal = true" />
            </div>

            <div class="row full-width justify-center q-my-lg" v-if="embeddedAssets.length">
              <q-list bordered separator class="self-center" style="width: 500px">
                <q-item v-for="asset in embeddedAssets" :key="asset.uid" class="row">
                  <q-item-section class="items-start col-8">{{asset.name}}</q-item-section>
                  <q-item-section class="col-3">
                    <q-btn flat @click="removeEmbeddedAsset(asset)">Remove</q-btn>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="showEmbeddedModal" @hide="embeddedContent = null; embeddedContentName = null;">
      <q-card class="bg-lmsBackground" style="min-width: 560px">
        <q-form  @submit="addEmbeddedContent" @reset="showEmbeddedModal = false" autocomplete="off">
          <q-card-section class="text-h5 primaryText-text">Embedded Content</q-card-section>
          <q-card-section>
            <q-input v-model="embeddedContentName" class="q-pa-md" filled label="Name" :rules="[$formRules.required('Name')]" />
            <q-input v-model="embeddedContent" class="q-pa-md" filled label="Embedded Video URL" :rules="[$formRules.required('URL'), this.$formRules.maximum(255)]" />
          </q-card-section>
          <q-card-actions class="q-pa-md" align="right">
            <q-btn color="primary" label="Add" type="submit" />
            <q-btn color="primary" flat label="Cancel" type="reset" />
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showEmbeddedErrorModal">
      <q-card class="bg-lmsBackground primaryText-text" style="min-width: 560px">
        <q-card-section class="text-h5 primaryText-text">Invalid Embedded URL</q-card-section>
        <q-card-section class="primaryText-text">
          The content you provided is not a valid URL. Please only use the source URL for your embedded content.
        </q-card-section>
        <q-card-section>
          If you have embedded code that looks similar to this:
        </q-card-section>
        <q-card-section>
          <q-banner class="bg-info">
            <code>
              &lt;iframe width="560" height="315" src="<span class="bg-warning">https://www.youtube.com/embed/1234</span>" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen&gt;&lt;/iframe&gt;
            </code>
          </q-banner>
        </q-card-section>
        <q-card-section>
          The only part you need from it, is what is located in the <span class="bg-info">src=""</span>
        </q-card-section>
        <q-card-section>
          In the instance above, you would paste in: <span class="bg-info">https://www.youtube.com/embed/1234</span>
        </q-card-section>
        <q-card-actions class="q-pa-md" align="right">
          <q-btn color="primary" label="Okay" @click="showEmbeddedErrorModal = false" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- <div class="row full-width q-mt-xl">
      <div class="col-12">
        <q-banner rounded class="bg-info primaryText-text" >
          By submitting your content to Mid-States&prime; CDN, you acknowledge that you agree to Mid-States&prime; <q-btn flat size="12px">Terms of Service and Guidelines</q-btn><br/>
          Please be sure not to violate others&prime; copyright or privacy rights. <q-btn flat size="12px">Learn More</q-btn>
        </q-banner>
      </div>
      <div class="col-12 row justify-center q-mt-lg">
        <q-btn flat>Upload Instructions</q-btn>
        <q-btn flat>Troubleshooting</q-btn>
      </div>
    </div> -->
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'

export default {
  props: ['media'],
  components: { FileUpload },
  data () {
    return {
      maxFileSize: 1000000000, // 1GB
      exceededFileSize: false,
      files: [],
      totalProgress: 0,
      fileUploadText: '',
      showProgressBar: false,
      progressBarWidth: '0px',
      postURL: ((process.env.USE_LOCAL === 'true') ? this.$consts.API_URL_LOCAL : this.$consts.API_URL) + this.$consts.MODULE_FILE_UPLOAD_URL,
      showEmbeddedModal: false,
      embeddedContent: null,
      embeddedContentName: null,
      embeddedAssets: [],
      showEmbeddedErrorModal: false
    }
  },
  computed: {
    uploadAllowed: function () {
      if (this.files.length > 0) {
        var disabled = true
        _.forEach(this.files, function (file) {
          if (!file.active && !file.success && file.error === '' && file.progress === '0.00') {
            disabled = false
          }
        })
        return disabled
      }
      return true
    }
  },
  watch: {
    errors: {
      handler: function (errors) {
        if (errors.files) {
          this.showError('#startUploadBtn')
        }
      },
      deep: true
    },
    files: {
      handler: function (files) {
        if (!this.$refs.upload.active && !this.$refs.upload && !this.$refs.upload.uploaded) {
          return
        }

        if (files.length === 0) {
          return
        }

        var tb = 0
        var tbu = 0
        var hasError = false
        var hasErrorCount = 0

        if (this.exceededFileSize && files.every(file => file.size < this.maxFileSize)) {
          this.exceededFileSize = false
        }

        _.forEach(files, (file) => {
          if (file.size > this.maxFileSize) {
            this.exceededFileSize = true
          }
          if (file.error) {
            hasErrorCount++
            hasError = true
          } else {
            tb += _.toInteger(file.size)
            tbu += _.toInteger(file.size) * _.toInteger(file.progress)
          }
        })

        this.totalProgress = _.floor(tbu / tb)
        this.updateProgressBarSize()

        if (this.$refs.upload && this.$refs.upload.uploaded) {
          if (hasErrorCount === files.length) {
            this.totalProgress = 100
            hasError = true
          }

          let uploadedMedia = this.media
          _.forEach(files, (file) => {
            if (!file.error) {
              var fileExists = false
              _.forEach(this.media, (value, key) => {
                if (value.uid === file.response.uid) {
                  fileExists = true
                }
              })

              if (fileExists) {
                return false
              }

              uploadedMedia = uploadedMedia.concat(file.response)
              uploadedMedia.forEach((asset, index) => {
                asset.display_order = index + 1
              })
            }
            this.$emit('update:media', uploadedMedia)
          })

          if (hasError) {
            this.totalProgress = 0
            this.showProgressBar = false
            this.$failureMsg('One or more files could not be uploaded')
          } else {
            this.totalProgress = 0
            this.showProgressBar = false
            this.updateProgressBarSize()
            this.$q.loading.hide()
            this.$successMsg('Files have been uploaded')
            this.$emit('nextTab')
          }
        }
      }
    }
  },
  methods: {
    selectFileUpload () {
      document.getElementById('file').click()
    },
    startUpload () {
      this.$q.loading.show()
      this.totalProgress = 0
      this.showProgressBar = true
      this.$refs.upload.active = true
      this.updateProgressBarSize()
    },
    updateProgressBarSize () {
      this.fileUploadText = 'File Upload & Processing Progress: %' + this.totalProgress
    },
    errorMessage (error) {
      if (error === 'size') {
        return 'File is to large.'
      } else if (error === 'extension') {
        return 'Invalid file type.'
      } else if (error === 'timeout') {
        return 'Upload timed out(typically due to slow upload speeds).'
      } else if (error === 'abort') {
        return 'Upload has been aborted.'
      } else if (error === 'network') {
        return 'Network connection was interupted.'
      } else if (error === 'server') {
        return 'The server could not process the file at this time.'
      } else if (error === 'denied') {
        return 'You do not have permissions to upload at this time.'
      }
    },
    async addEmbeddedContent () {
      if (this.$isURL(this.embeddedContent)) {
        this.$loading(true)
        try {
          let res = await this.$axios.post(this.$consts.MODULE_FILE_UPLOAD_URL, { name: this.embeddedContentName, content: this.embeddedContent })
          this.media.push(res)
          this.$emit('update:media', this.media)
          this.showEmbeddedModal = false
          this.embeddedContentName = null
          this.embeddedContent = null
          this.embeddedAssets.push(res)
          this.$loading(false)
        } catch (e) {
          this.$loading(false)
          this.$failureMsg()
        }
      } else {
        this.showEmbeddedErrorModal = true
      }
    },
    async removeEmbeddedAsset (asset) {
      this.$loading(true, 'Deleting')
      try {
        await this.$axios.delete(`${this.$consts.MEDIA_DELETE_URL}/${asset.uid}`)
        this.embeddedAssets.splice(this.embeddedAssets.findIndex(a => a.uid === asset.uid), 1)
        this.media.splice(this.media.findIndex(a => a.uid === asset.uid), 1)
        this.$loading(false)
        this.$successMsg('Removed Embedded Content')
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    }
  }
}
</script>

<style lang="stylus">
#uploadProgressDiv
  text-align center

#uploadProgressDivCenter
  position relative
  float right

#uploadProgressBar
  height 25px
  z-index 9998

.lightProgressBarDiv
  background-color white
  .progressText
    color black
    text-shadow 0px 0px 10px white;

.darkProgressBarDiv
  background-color black
  .progressText
    color white
    text-shadow 0px 0px 10px black;

.progressText
  z-index 9999
  font-weight bold

.file-upload-container
  width 100%
.uploader-div
  .upload
    text-align center
    width 1500px
    ul
      text-align left

    label
      .btn
        margin-bottom 0
        margin-right 1rem

    .drop-active
      top 0
      bottom 0
      right 0
      left 0
      position fixed
      z-index 9999
      opacity .6
      text-align center
      background #000

      h3
        margin -.5em 0 0
        position absolute
        top 50%
        left 0
        right 0
        -webkit-transform translateY(-50%)
        -ms-transform translateY(-50%)
        transform translateY(-50%)
        font-size 40px
        color #fff
        padding 0
</style>
